var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_vm._v(" Cashapp Deposits ")]),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","items":_vm.computedItems,"fields":_vm.fields,"columnFilter":{ external: true, lazy: true },"columnFilterValue":_vm.currentFilters,"sorter":{ external: true },"sorterValue":_vm.currentSort,"loading":_vm.isLoading,"responsive":true},on:{"update:columnFilterValue":function($event){_vm.currentFilters=$event},"update:column-filter-value":[function($event){_vm.currentFilters=$event},_vm.changeFilter],"update:sorter-value":_vm.changeSort},scopedSlots:_vm._u([{key:"username",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.playerDetail.playerUsername)+" ")])]}},{key:"requestedAmount",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.paymentDetail)?_c('span',[_vm._v(_vm._s(item.paymentDetail.requestedAmount))]):_vm._e(),(!item.paymentDetail)?_c('span',[_vm._v("-")]):_vm._e()])]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.amount)?_c('span',[_vm._v(_vm._s(item.amount))]):_vm._e(),(!item.amount)?_c('span',[_vm._v("-")]):_vm._e()])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.status == "C")?_c('CBadge',{attrs:{"color":"success"}},[_vm._v("Complete")]):_vm._e(),(item.status == "V")?_c('CBadge',{attrs:{"color":"success"}},[_vm._v("Voided")]):_vm._e()],1)]}},{key:"status-filter",fn:function(ref){
var item = ref.item;
return [_c('CSelect',{staticClass:"table-select",attrs:{"size":"sm","options":[ { value: null, label: 'All' }, { value: 'C', label: 'Complete' }, { value: 'V', label: 'Voided' } ]},on:{"change":function($event){return _vm.changeSelectFilter($event,'status')}},model:{value:(_vm.currentFilters.status),callback:function ($$v) {_vm.$set(_vm.currentFilters, "status", $$v)},expression:"currentFilters.status"}})]}},{key:"createdAt-filter",fn:function(ref){
var item = ref.item;
return [_c('CSelect',{staticClass:"table-select",attrs:{"size":"sm","options":[ { value: 10, label: '10' }, { value: 50, label: '50' }, { value: 100, label: '100' }, { value: 500, label: '500' }, { value: 1000, label: '1000' } ]},on:{"change":function($event){return _vm.changeItemsPerPage($event)}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})]}},{key:"loading",fn:function(){return [_c('CElementCover',{attrs:{"boundaries":[{ sides: ['top'], query: 'td' }, { sides: ['bottom'], query: 'tbody' }]}},[_c('CSpinner',{attrs:{"color":"secondary"}})],1)]},proxy:true},{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center my-5"},[_vm._v(" No data ")])]},proxy:true}])}),_c('CPagination',{attrs:{"align":"center","activePage":_vm.currentPage,"pages":_vm.pages},on:{"update:activePage":[function($event){_vm.currentPage=$event},_vm.changePage],"update:active-page":function($event){_vm.currentPage=$event}}})],1)],1),_c('CModal',{attrs:{"title":_vm.opModalTitle,"color":_vm.opModalColor,"centered":true,"show":_vm.opModal},on:{"update:show":[function($event){_vm.opModal=$event},_vm.opModalConfirm]}},[_vm._v(" "+_vm._s(_vm.opModalText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }